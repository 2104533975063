import styled from '@emotion/styled'
import {
    Grid,
    TableContainer,
    Table,
    tableCellClasses,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Box,
    Pagination,
    Stack,
    Backdrop,
    CircularProgress,
    FormControl,
    Select,
    MenuItem,
    TableSortLabel
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BASE_COLOR } from '../../../shared/constants/color.constant'
import { IDomaines, User } from '../interfaces/utilisateur.interface'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'

import ModaleValidationComponent from '../../../shared/components/Modale/modale-validation.component'
import userServices from '../services/utilisateur.service'
import { RolesType } from '../../../shared/constants/roleType'
import { DayDispo } from '../../../shared/constants/Days'
import { DateFormater, DateFormaterSans } from '../../../shared/functions/date'
import FilterUser from '../../../shared/components/FilterUser/filter-user.component'
import dayjs, { Dayjs } from 'dayjs'
import { toast } from 'react-toastify'
import FilterUserCreateur from '../../../shared/components/FilterUser/filter-user-createur.component'
import Tooltip from '@mui/material/Tooltip';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: BASE_COLOR.CONTAINER,
        border: 0
    },
    [`& .${tableCellClasses.root}`]: {
        borderBottom: 'none'
    }
}))

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: BASE_COLOR.CONTAINER,
        fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15
    }
}))

const UtilisateurCreateurComponent: React.FC<any> = () => {
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [totalPage, setTotalPage] = useState(0)
    const [domaineSelected, setDomaineSelected] = useState<IDomaines | null>(null)
    const [inputName, setInputName] = useState('')
    const [inputQuery, setInputQuery] = useState<any>({})
    const [roleId, setRoleId] = useState('')
    // const [roleId, setRoleId] = useState('653a60fc15d3b5646ca112f9')
    const [needingTva, setNeedingTva] = useState<boolean>()
    const [pageLastButton, setPageLastButton] = useState<number>()
    const [_id, set_id] = useState<string>("")
    const [villeSelected,setVilleSelected] = useState<string>("")
    const [niveauSelected, setNiveauSelected] = useState<string | null>(null)
    const [shouldBePayeur, setShouldBePayeur] = useState<boolean>(false)

    const handleChangeRowsPerPage = (value: number) => setRowsPerPage(value)

    const [createurs, setCreateurs] = useState<User[]>([])
    // const [itemParPage, setItemParPage] = useState('10')
    // const handleChange = (event: SelectChangeEvent) => {
    //     setItemParPage(event.target.value)
    // }
    const navigate = useNavigate()

    const onChangeDomaine = (domaine?: IDomaines) => setDomaineSelected(domaine ?? null)

    const onChangeVille = (ville: string) => setVilleSelected(ville)

    const onChangeName = (name: string) => setInputName(name)

    const onChangeNeedTva = (needTva: boolean) => setNeedingTva(needTva)

    const onChange_id = (_id: string) => set_id(_id)

    const onChangeNiveau = (niveau?: string) => setNiveauSelected(niveau ?? null)

    console.log("needingTva>>>>>>>>>>"+needingTva)

    const onChangeQuery = (query: any) =>
        setInputQuery((prevQuery: any) => ({
            ...prevQuery,
            ...query
        }))

    useEffect(() => {
        userServices.getRoles().then(res => {
            const roleId = res.data.roles.find((elt: any) => String(elt.type) === RolesType.CREATOR)?._id
            setRoleId(roleId)
        })
    }, [])

    const [reload, setReload] = useState(0)
    useEffect(() => {
        setLoading(true)
        if (roleId) {
            userServices
                .getByRole(page, roleId, rowsPerPage, inputName === '' ? undefined : inputName, domaineSelected?._id,needingTva, _id,villeSelected,niveauSelected ? niveauSelected: '',shouldBePayeur, inputQuery)
                .then(res => {
                    /*Filtrer pour que seulent les user ayant un tva apparaissent*/
                    // const filteredData = res.data.data.filter((user: User) => user.idEntreprise?.tva !== '');  
                    // setCreateurs(res.data.data)
                    setCreateurs(res.data.data)
                     
                    setTotalPage(res.data.totalPage)
                    console.log("TotalPage selon les donnees: ",res.data.totalPage)
                    console.log("rowsPerPage: ",rowsPerPage)

                    console.log("totalElements: ",res.data.totalElements)
                    res.data.data.map((data: any) => console.log("USERS👍: ",data.firstname," " ,data.lastname))

                    setPageLastButton(Math.ceil(createurs.length / rowsPerPage))
                    setRowsPerPage(rowsPerPage)
                    
                })
                .catch(error => console.log(error))
                .finally(() => setLoading(false))
        }
        //Ajout de neegingTva dans les dependances pour que des etats changes a chaque fois qu'il change
    }, [page, rowsPerPage, inputName, domaineSelected,villeSelected,niveauSelected, reload, inputQuery, roleId, needingTva, totalPage, _id])
    useEffect(() => {
        // if (needingTva || _id || villeSelected || niveauSelected) {
            setLoading(true)
            setPage(1)   
            console.log("setpage1")
            setLoading(false)
        // }
        //A chaque fois que needingT change,d'ou la dependance [needingTva],on retourne vers la premiere page
    },[needingTva,_id,villeSelected,niveauSelected])

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage)
    }

    const labelDisplayedRows = ({ from, to, count, page }: any) => {
        return `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`
    }

    const [open, setOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState<User['_id'] | null>(null)

    const [order, setOrder] = useState<'asc' | 'desc'>('asc')
    const [orderBy, setOrderBy] = useState<string>('fullName')

    const handleSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc'
        const newOrder = isAsc ? 'desc' : 'asc'
        setOrder(newOrder)
        setOrderBy(property)

        const sorted = [...createurs].sort((a, b) => (newOrder === 'asc' ? handleSortBy(a, b, property) : handleSortBy(b, a, property)))

        setCreateurs(sorted)
    }

    const handleSortBy = (a: User, b: User, property: string) => {
        switch (property) {
            case 'fullName':
                return `${a['lastname']} ${a['firstname']}`.localeCompare(`${b['lastname']} ${b['firstname']}`)
            case 'ville':
                return (a.idEntreprise?.citeEP ?? '').localeCompare(b.idEntreprise?.citeEP ?? '')
            case 'trajet':
                return (
                    (a.trajet && a.trajet.length === 2 ? Number.parseInt(a.trajet[1], 0) - Number.parseInt(a.trajet[0], 0) : 0) -
                    (b.trajet && b.trajet.length === 2 ? Number.parseInt(b.trajet[1], 0) - Number.parseInt(b.trajet[0], 0) : 0)
                )
            case 'villeEviter':
                return (
                    a.villeEviter && a.villeEviter.length > 0
                        ? a.villeEviter.sort((prev, curr) => prev.address.localeCompare(curr.address))[0].address
                        : ''
                ).localeCompare(
                    b.villeEviter && b.villeEviter.length > 0
                        ? b.villeEviter.sort((prev, curr) => prev.address.localeCompare(curr.address))[0].address
                        : ''
                )
            case 'domaine':
                return (a.idEntreprise?.domaine?.name ?? '').localeCompare(b.idEntreprise?.domaine?.name ?? '')
            case 'domaineEviter':
                return (
                    a.domaineEviter && a.domaineEviter.length > 0
                        ? a.domaineEviter.sort((prev, curr) => prev.name.localeCompare(curr.name))[0].name
                        : ''
                ).localeCompare(
                    b.domaineEviter && b.domaineEviter.length > 0
                        ? b.domaineEviter.sort((prev, curr) => prev.name.localeCompare(curr.name))[0].name
                        : ''
                )
            case 'nomEntreprise':
                return (a.idEntreprise?.nomEntreprise ?? '').localeCompare(b.idEntreprise?.nomEntreprise ?? '')
            case 'chiffreDaffaire':
                return (a.chiffreDaffaire ?? '').localeCompare(b.chiffreDaffaire ?? '')
            default:
                return (a[property] ?? '').localeCompare(b[property])
        }
    }

    const deleteCreateur = (idUser: User['_id']) => {
        userServices
            .deleteAUser(idUser)
            .then(() => {toast.success('Utilisateur supprimé avec succès.')})
            .catch(console.log)
            .finally(() => {
                setReload(prev => ++prev)
            })
    }

    return (
        <FilterUserCreateur
            domaine={domaineSelected ?? undefined}
            onChangeDomaine={onChangeDomaine}
            name={inputName}
            onChangeName={onChangeName}
            onChangeUser={onChangeQuery}
            // isTva={true}
            needTva={needingTva}
            onChangeNeedTva={onChangeNeedTva}
            onChange_id={onChange_id}
            // ville={villeSelected}
            onChangeVille={onChangeVille}
            niveau={niveauSelected ?? undefined}
            onChangeNiveau={onChangeNiveau}
            // roleId={roleId}
        >
            {/* Filtres à appliquer en fin du sprint 3 */}
            {/* <Grid container alignItems="center" paddingY={2} spacing={1} gap={1}>
                <Grid item xs={12} md={3}>
                    <Input placeholder="Nom" fullWidth />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        {...defaulProps}
                        id="test"
                        clearOnEscape
                        renderInput={params => <TextField {...params} label="Domaine" variant="standard" sx={{ mb: 2 }} />}
                    />
                </Grid>
            </Grid> */}
            <Box sx={{ overflowX: 'auto', width: '100%' }}>
                <Box sx={{ overflowX: 'auto', width: '100%', position: 'absolute', top: 0, left: 0, zIndex: 1 }}>
                    <div style={{ height: 0 }}> {/* Pour maintenir la hauteur du conteneur de défilement */} </div>
                </Box>
            {/* <TableContainer component="div" sx={{ maxWidth: 1200, width: '100%', display: 'block' }}> */}
            <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                {createurs.length === 0 && !loading ? (
                    <p style={{ display: 'flex', justifyContent: 'center' }}>Aucun enregistrement trouvé</p>
                ) : (
                    <Table
                        stickyHeader
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: 'none'
                            }
                        }}
                        className="border table-auto mx-9"
                    >
                        <TableHead>
                            <TableRow>
                            <StyledTableHeader sortDirection={orderBy === '_id' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === '_id' ? order : 'asc'}
                                        onClick={() => handleSort('_id')}
                                    >
                                        Matricule
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'fullName' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'fullName' ? order : 'asc'}
                                        onClick={() => handleSort('fullName')}
                                    >
                                        Nom et prénom
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'statusUser' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'statusUser' ? order : 'asc'}
                                        onClick={() => handleSort('statusUser')}
                                    >
                                        Niveau
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'statusUser' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'email' ? order : 'asc'}
                                        onClick={() => handleSort('email')}
                                    >
                                        Email
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'numero' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'numero' ? order : 'asc'}
                                        onClick={() => handleSort('numero')}
                                    >
                                        Téléphone
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'ville' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'ville' ? order : 'asc'}
                                        onClick={() => handleSort('ville')}
                                    >
                                        Ville
                                    </TableSortLabel>
                                </StyledTableHeader>
                                {/* <StyledTableHeader sortDirection={orderBy === 'trajet' ? order : false} sx={{ minWidth: 150 }}>
                                    Trajets
                                </StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'villeEviter' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'villeEviter' ? order : 'asc'}
                                        onClick={() => handleSort('villeEviter')}
                                    >
                                        Villes évitées
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sx={{ minWidth: 150 }}>Disponibilités</StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'typeMission' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'typeMission' ? order : 'asc'}
                                        onClick={() => handleSort('typeMission')}
                                    >
                                        Type de mission
                                    </TableSortLabel>
                                </StyledTableHeader> */}
                                <StyledTableHeader sortDirection={orderBy === 'domaine' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'domaine' ? order : 'asc'}
                                        onClick={() => handleSort('domaine')}
                                    >
                                        Domaines
                                    </TableSortLabel>
                                </StyledTableHeader>
                                {/* <StyledTableHeader sortDirection={orderBy === 'domaineEviter' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'domaineEviter' ? order : 'asc'}
                                        onClick={() => handleSort('domaineEviter')}
                                    >
                                        Domaines évités
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sx={{ minWidth: 150 }}>Siret Entreprise</StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'tva' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'tva' ? order : 'asc'}
                                        onClick={() => handleSort('tva')}
                                    >
                                        TVA
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'apeCode' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'apeCode' ? order : 'asc'}
                                        onClick={() => handleSort('apeCode')}
                                    >
                                        Code APE
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'chiffreDaffaire' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'chiffreDaffaire' ? order : 'asc'}
                                        onClick={() => handleSort('chiffreDaffaire')}
                                    >
                                        Chiffre d'affaire
                                    </TableSortLabel>
                                </StyledTableHeader> */}
                                <StyledTableHeader sortDirection={orderBy === 'nomEntreprise' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'nomEntreprise' ? order : 'asc'}
                                        onClick={() => handleSort('nomEntreprise')}
                                    >
                                        Entreprise
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sortDirection={orderBy === 'createdAt' ? order : false} sx={{ minWidth: 150 }}>
                                    <TableSortLabel
                                        active={true}
                                        direction={orderBy === 'createdAt' ? order : 'asc'}
                                        onClick={() => handleSort('createdAt')}
                                    >
                                        Créé le
                                    </TableSortLabel>
                                </StyledTableHeader>
                                <StyledTableHeader sx={{ minWidth: 150 }}>
                                        Présence de pièces d'identité
                                </StyledTableHeader>
                                <StyledTableHeader sx={{ minWidth: 150 }}>Actions</StyledTableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {createurs.map((row: User, i: number) => (
                                <StyledTableRow key={i}>
                                    <TableCell className="border">{`HWK${row._id.slice(-5)}`}</TableCell>
                                    <TableCell className="border">{`${row.firstname} ${row.lastname}`}</TableCell>
                                    <TableCell className="border">{row.statusUser}</TableCell>
                                    <TableCell className="border">{row.email}</TableCell>
                                    <TableCell className="border">+33{row.numero}</TableCell>
                                    <TableCell className="border">{row.idEntreprise?.citeEP ?? ''}</TableCell>
                                    {/* <TableCell className="border">{`${
                                        row.trajet && row.trajet.length === 2 ? row.trajet[0] + ' à ' + row.trajet[1] : ''
                                    }`}</TableCell>
                                    <TableCell className="border">{row.villeEviter.map(elt => elt.address).join(' - ')}</TableCell>
                                    <TableCell className="border">
                                        {row.disponibility &&
                                            row.disponibility.length > 0 &&
                                            row.disponibility.map(elt => {
                                                return (
                                                    <div>
                                                        {elt.label} : {DayDispo(elt.day)}
                                                    </div>
                                                )
                                            })}
                                    </TableCell>
                                    <TableCell className="border">{row.typeMission}</TableCell> */}
                                    <TableCell className="border">{row.idEntreprise?.domaineEntreprise?.name ?? ''}</TableCell>
                                    {/* <TableCell className="border">
                                        {row.domaineEviter ? row.domaineEviter.map(elt => elt.name + ' ') : ''}
                                    </TableCell>
                                    <TableCell className="border">{row.idEntreprise?.siretEntreprise ?? ''}</TableCell>
                                    <TableCell className="border">{row.idEntreprise?.tva ?? ''}</TableCell>
                                    <TableCell className="border">{row.idEntreprise?.apeCode ?? ''}</TableCell>
                                    <TableCell className="border">{row.chiffreDaffaire}</TableCell> */}
                                    <TableCell className="border">{row.idEntreprise?.nomEntreprise ?? ''}</TableCell>
                                    <TableCell className="border">{DateFormaterSans(row.createdAt.toString())}</TableCell>
                                    <TableCell className="border">
                                    <Grid container gap={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {row.pieceJustificatif && row.pieceJustificatif.length > 0 ? (
                                            <input
                                                type="checkbox"
                                                checked={true}
                                                disabled // Rendre le checkbox non éditable
                                            />
                                        ):(
                                            <input
                                                type="checkbox"
                                                checked={false}
                                                disabled // Rendre le checkbox non éditable
                                            />
                                        )}
                                    </Grid>
                                    </TableCell>
                                    <TableCell className="border">
                                        {/* <Grid container alignItems={'center'} gap={1}> */}
                                        <Grid container gap={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Tooltip title="Voir les détails du créateur">
                                            <VisibilityIcon color="primary" onClick={() => navigate(`/utilisateur/createur/${row._id}`)} />
                                        </Tooltip>
                                            {/* <EditIcon color="primary" /> */}
                                            <Tooltip title="Supprimer le créateur">
                                            <DeleteIcon
                                                color="error"
                                                onClick={() => {
                                                    setOpen(true)
                                                    setSelectedItem(row._id)
                                                }}
                                            />
                                            </Tooltip>
                                        </Grid>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            </Box>
            {createurs.length > 0 && (
                <Box sx={{ display: 'flex', mt: 5 }}>
                    <Grid container alignItems="center" justifyContent="end" gap={1}>
                        <Stack spacing={2}>
                            <Pagination
                                showFirstButton={page === 1}
                                showLastButton={page === pageLastButton}
                                count={totalPage}
                                page={page}
                                onChange={handleChangePage}
                                color="primary"
                                shape="rounded"
                            />
                        </Stack>

                        <FormControl sx={{ m: 1, minWidth: 120, background: 'white' }} size="small">
                            <Select
                                // value={rowsPerPage}
                                value={rowsPerPage}
                                onChange={e => handleChangeRowsPerPage(+e.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={'5'}>5 / page</MenuItem>
                                <MenuItem value={'10'}>10 / page</MenuItem>
                                <MenuItem value={'25'}>25 / page</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Box>
            )}
            <ModaleValidationComponent
                openModale={open}
                setOpen={setOpen}
                message={`Voulez-vous supprimer l'utilisateur`}
                handleValidate={() => {
                    if (selectedItem) deleteCreateur(selectedItem)
                }}
            />
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>
        </FilterUserCreateur>
    )
}

export default UtilisateurCreateurComponent
